export const TRANS_FEATURE = 'dialogMixinTranslations';

export const TRANS_KEYS = {
  title: 'dialogMixinTranslations_GUEST_LOGIN_TITLE',
  subtitle: 'dialogMixinTranslations_GUEST_LOGIN_SUBTITLE',
  submitButton: 'SMRegister_GO',
  passwordPlaceholder: 'PasswordLogin_Password',
  generalError: 'SMForm_Error_General_Err',
  errMsgPrefix: 'SMForm_Error_',
  blankPassword: 'SMForm_Error_Password_Blank',
  closeDialog: 'dialogMixinTranslations_Close_Dialog',
};

export const DEFAULT_VALUES = {
  title: 'Guest Area',
  subtitle: 'Please enter the password below.',
  submitButton: 'Go',
  passwordPlaceholder: 'Password',
  generalError: 'Something went wrong. Try again later.',
  blankPassword: 'Make sure you enter a password.',
  closeDialog: 'Close',
};

export const TEST_IDS = {
  errorMessage: 'errorMessage',
  closeButton: 'closeButton',
  submitButton: 'submitButton',
  password: 'password',
};
